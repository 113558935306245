.react-tabs__tab-list {
  position: relative;
  padding-left: 0px !important;
}

.react-tabs__tab {
  padding: 0 20px 10px;
  margin-right: 3px;
  text-align: center;
  border-color: #b8b8b8;
  background: #e8e8e8;
  transition: all 0.3s ease;

  &:not(.react-tabs__tab--disabled) {
    &.react-tabs__tab--selected,
    &:hover { background-color: #FFF; }
  }

  &.nationalResults {
    position: absolute;
    right: 0;
    padding-bottom: 0px;

    a {
      text-decoration: none;
      &:hover { color: var(--bs-body-color); }
      .tabTitle {
        line-height: 66px;
        margin-top: 0px;
      }
    }
  }

  .submissionCount {
    position: absolute;
    top: -11px;
    right: 0px;
    background: #626262;
    color: #FFF;
    font-size: 14px;
    font-weight: 500;
    border-radius: 100%;
    padding: 0px 8px;
  }

  .tabTitle {
    display: block;
    font-size: 20px;
    margin: 8px 0 0 0;
  }

  .daysLeft {
    display: block;
    font-size: 14px;
    margin-top: -3px;
  }
}

.nationalTabs {
  .react-tabs__tab {
    padding-bottom: 0px;
    .tabTitle {
      margin-top: 0px;
      line-height: 66px;
    }
  }
}

.react-tabs__tab-panel { margin-top: 70px; }

table {
  width: 100%;

  tr {
    :last-child {
      td {
        border-bottom: 0;
      }
    }
  }

  th {
    padding: 0.75rem 0.75rem 5px;
    text-align: center;
    border-bottom: 1px solid #888;
    font-weight: 400;
    text-transform: uppercase;

    & span {
      position: relative;
      top: -2px;
      margin-left: 12px;
      color: #b5b5b5;
      font-size: 11px;
      &.active { color: #d5d9e9; }
    }
  }

  td {
    margin: 0;
    padding: 0.75rem;
    text-align: center;
  }

  tbody {
    tr {
      transition: all 0.3s ease;
      border-bottom: 0.5px solid #d2d2d2;
      cursor: pointer;

      &:hover { background: #adbffd !important; }
      &.long-list { background: #edf1ff; }
      &.dismiss { background: #e1e1e1; }

      td:nth-of-type(2),
      td.artist { text-decoration: underline; }
    }
  }

  td:nth-of-type(3),
  td:nth-of-type(4) { cursor: default; }
}

.rating {
  max-width: 120px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .ratingCircle {
    border-radius: 100%;
    border: 1px solid #576289;
    padding: 7px;
    cursor: pointer;
    transition: all 0.3s ease;

    &.active,
    &:hover { background: #576289; }
  }
}

.position {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;

  .positionBtn {
    padding: 0.25rem 1rem;
    font-size: 17px;

    &.active,
    &:hover {
      color: var(--bs-body-color);
      background: var(--bs-secondary);
    }
  }
}

.sidebarPosition {
  .positionBtn {
    width: 100%;
    margin-top: 0.75rem;
    padding: 0.25rem 1rem;
    font-size: 17px;

    &.active,
    &:hover {
      color: var(--bs-body-color);
      background: var(--bs-secondary);
    }
  }
}

#Error-Bar {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 124px;
  width: 100%;
  height: 52px;
  padding: 0 2rem;
  background: #FF4B4C;
  z-index: 1;

  &.active { display: flex; }

  p {
    margin: -3px 0 0 0;
    font-weight: 500;
    font-size: 18px;
    color: #FFF;
  }
}

.rating .ratingCircle.disabled { pointer-events: none; opacity: .65; }

#Submitted {
  margin-top: 100px;

  img {
    max-width: 40px;
    position: relative;
    top: -3px;
  }
  span {
    font-size: 28px;
    margin-left: 18px;
  }
}

#National-Results {
  table {
    th,
    td { text-align: left; }

    /*&.short:not(.showExtra) {
      td:nth-of-type(5),
      td:nth-of-type(6),
      th:nth-of-type(5),
      th:nth-of-type(6) { display: none; }
    }*/
  }
}

.images {
  .card-body {
    a > i {
      position: absolute;
      right: 1.5rem;
      bottom: 1.5rem;
      font-size: 30px;
    }
  }

  .view {
    display: block;
    position: relative;
    padding: 1rem;
    cursor: pointer;

    .view-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background: rgba(0, 0, 0, 0.3);
      z-index: 1;
      opacity: 0;
      transition: all 0.3s ease;

      i {
        color: #FFF;
        font-size: 30px;
        transform: translate(50%, -50%);
        position: absolute;
        top: 50%;
        right: 50%;
      }
    }

    &:hover .view-overlay { opacity: 1; }
  }
}

.ReactModal__Overlay.ReactModal__Overlay--after-open { z-index: 9999 !important; }

@media (max-width: 767.98px) {
  .react-tabs__tab {
    display: block;
    margin-right: 0px;
  }

  table {
    thead { display: none; }
    tr { display: block; padding: 1rem; }
    td { display: block; padding: 0.5rem 0rem; text-align: center !important; }

    .position {
      display: block;
      .positionBtn {
        width: 100%;
        margin-top: 0.5rem;
        padding: 0.25rem 1rem;
        font-size: 17px;

        &.active,
        &:hover {
          color: var(--bs-body-color);
          background: var(--bs-secondary);
        }
      }
    }
  }
}

@media (max-width: 991.98px) {
  .react-tabs__tab-list:not(.nationalTabs) .react-tabs__tab {
    display: block;
    margin-right: 0px;
  }

  .react-tabs__tab.nationalResults { position: relative; }
}

@media (max-width: 1199.98px) {
  .react-tabs__tab {
    padding: 0 15px 10px;
    .tabTitle { font-size: 19px; }
  }
}
