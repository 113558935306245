/* Import Fonts */
@font-face {
   font-family: 'Founder Grotesk';
   src: url('../webfonts/founders-grotesk-web-regular.eot');
   src: url('../webfonts/founders-grotesk-web-regular.eot?#iefix') format('embedded-opentype'),
        url('../webfonts/founders-grotesk-web-regular.woff2') format('woff2'),
        url('../webfonts/founders-grotesk-web-regular.woff') format('woff');
   font-weight: 400;
   font-style: normal;
}

@font-face {
   font-family: 'Founder Grotesk';
   src: url('../webfonts/founders-grotesk-web-medium.eot');
   src: url('../webfonts/founders-grotesk-web-medium.eot?#iefix') format('embedded-opentype'),
        url('../webfonts/founders-grotesk-web-medium.woff2') format('woff2'),
        url('../webfonts/founders-grotesk-web-medium.woff') format('woff');
   font-weight: 500;
   font-style: normal;
}

@font-face {
   font-family: 'Founder Grotesk';
   src: url('../webfonts/founders-grotesk-web-bold.eot');
   src: url('../webfonts/founders-grotesk-web-bold.eot?#iefix') format('embedded-opentype'),
        url('../webfonts/founders-grotesk-web-bold.woff2') format('woff2'),
        url('../webfonts/founders-grotesk-web-bold.woff') format('woff');
   font-weight: 600;
   font-style: normal;
}

@font-face {
   font-family: 'Founder Grotesk';
   src: url('../webfonts/founders-grotesk-web-regular-italic.eot');
   src: url('../webfonts/founders-grotesk-web-regular-italic.eot?#iefix') format('embedded-opentype'),
        url('../webfonts/founders-grotesk-web-regular-italic.woff2') format('woff2'),
        url('../webfonts/founders-grotesk-web-regular-italic.woff') format('woff');
   font-weight: 400;
   font-style: italic;
}



/* General */
html, body {
	margin: 0;
	padding: 0;
}
/* body {
	font-family: 'Founder Grotesk', Helvetica Neue, Helvetica, Arial, sans-serif;
  font-size: 19px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
 
body {
  font-family: neue-haas-unica, Arial, sans-serif;
  font-weight: 400;
  font-style: normal;
  font-size: 17px;
  line-height: 1.8em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --bs-blue: #ADBFFD;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #ADBFFD;
  --bs-secondary: #E8B9FA;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-primary-rgb: 173,191,253;
  --bs-secondary-rgb: 232,185,250;
  --bs-success-rgb: 25,135,84;
  --bs-info-rgb: 13,202,240;
  --bs-warning-rgb: 255,193,7;
  --bs-danger-rgb: 220,53,69;
  --bs-light-rgb: 248,249,250;
  --bs-dark-rgb: 33,37,41;
  --bs-white-rgb: 255,255,255;
  --bs-black-rgb: 0,0,0;
  --bs-body-color-rgb: 33,37,41;
  --bs-body-bg-rgb: 255,255,255;
  --bs-font-sans-serif: 'Founder Grotesk', Helvetica Neue, Helvetica, Arial, sans-serif;
  --bs-font-monospace: SFMono-Regular,Menlo,Monaco,Consolas,"Liberation Mono","Courier New",monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: var(--bs-font-sans-serif);
  --bs-body-font-size: 1rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #212529;
  --bs-body-bg: #fff;
}

img { max-width: 100%; }

a { color: var(--bs-body-color); }
/* h1 {
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-weight: 400;
}
h2 {
  margin: 2.5rem 0 1rem 0;
  text-transform: uppercase;
  font-weight: 400;
}
h3 {
  margin-top: 2rem;
  text-transform: uppercase;
  font-weight: 400;
} */
h1 {
  font-size: 40px;
  margin-top: 0px;
  padding-bottom: 30px;
  text-transform: uppercase;
  margin-bottom: 25px;
  font-weight: 500;
  }
  
  h3 {
  font-size: 32px;
      line-height: 35px;
      margin-top: 24px;
      margin-bottom: 20px;
      text-transform: uppercase;
  }
  
  h4 {
  font-size: 24px;
      line-height: 26px;
      text-transform: uppercase;
  }
  
h5 { font-weight: 400; }
ul { padding-left: 25px !important; }
.modal-body ul { margin-top: 0px; }



/* Bootstrap overrides */
.container { padding: 2rem; }
.alert p { margin-bottom: 0; }

.btn-lg {
  background-color: #f8f9fa;
  border: solid 1px #c4c5c9;
  color: #4d4f54;
  text-transform: uppercase;
  i { font-size: 14px; margin-left: 0.5em; }
  &:hover { color: #7a8cca;; }
}
.btn-lg.submit {
  background: #ffbaba;
  border: none;
  &:hover { background: #e8b9fa; color: #333; }
}
.btn-grey {
  background-color: #6F6F6F;
  border-color: #6F6F6F;
  color: #ffffff;
  &:hover {
    background-color: #949494;
    border-color: #949494;
    color: #ffffff;
  }
}

.navbar {
  .container { max-width: none; padding: 1rem 2rem; }
  .navbar-brand {
  	width: 100%;
    max-width: 251px;
    padding: 0;
    margin: 0;
    img { max-height: 60px; }
  }

  .navbar-collapse { margin-top: 2rem; }

  .nav-item {
    span {
      line-height: 27px;
      cursor: pointer;
    }
  }

  .dropdown-toggle:after {
    position: relative;
    top: 3px;
    left: 3px;
  }

  .dropdown-item.active,
  .dropdown-item:active { background: #eee; }
}

.form-label { text-transform: uppercase; font-weight: 400 !important; }
.input-group .form-floating { flex: 1 1 auto; width: 1%; }

footer.actions {
  .container { border-top: 1px solid #cdcddc; }
}

footer.footer {
  .container { padding: 1.5rem 2rem; }
}

span.user {
  display: inline-block;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  border-radius: 10px;
  background: rgba(var(--bs-primary-rgb));
  font-size: 20px;
  i { margin-right: 10px; }
}


/* Modal */
.modal-header { padding-top: 0px; }
.ReactModalPortal .btn-close {
  top: 10px !important;
  right: 10px !important;
}


/* My Submissions Table */
.mySubmissions tr { cursor: default; }
.mySubmissions th { text-align: left; }
.mySubmissions td {
  text-decoration: none !important;
  text-transform: capitalize;
  text-align: left;
}
.mySubmissions .position { justify-content: left; }
.mySubmissions .position .disabled { pointer-events: none; opacity: 0.3; }


/* User Management Table */
.userManagement {
  tr { cursor: default; }
  th { text-align: left; }
  td {
    text-decoration: none !important;
    text-align: left;
  }
  .position { justify-content: left; }
}


/* Pagination */
.pagination {
  align-items: center;
  gap: 20px;
  justify-content: center;
  margin-top: 25px;
  font-size: 18px;
  
  button {
    background: none;
    border: none;
    font-weight: bold;
    font-family: Serif;
    font-size: 24px;
    line-height: 17px;
    color: #212529;
    
    &:hover {
      color: #adbffd;
    }
    &:disabled {
      color: #AAA;
    }
  }
  
  select {
    width: auto;
  }
}

/* Bootstrap styles.css */
.mr-1 {
  margin-right: 1em;
}

.tab-offset {
  position: relative;
  top: 20px;
}

.nav-tabs { padding-left: 0px !important; }

.nav-tabs .nav-link.active {
  background: none;
  border-color: transparent;
}

.nav-tabs .nav-link.active > .badge {
  background-color: var(--bs-secondary) !important;
}

.start-80 {
  left: 80% !important;
}



/* Bootstrap Navigation-Clean.css */
.navigation-clean {
  background: linear-gradient(90deg, #ADBFFD 0%, rgba(232, 185, 250, 0.96) 100%);
  padding-top: 1rem;
  padding-bottom: 1rem;
  color: #333;
  border-radius: 0;
  box-shadow: none;
  border: none;
  margin-bottom: 0;
}

.navigation-clean .navbar-toggler {
  border: none;
  padding: 0;
  color: #888;
}

.navigation-clean .navbar-toggler:hover, .navigation-clean .navbar-toggler:focus {
  box-shadow: 0 0 8px 0.05rem;
}

.navbar-nav { padding-left: 0px !important; }

.navigation-clean.navbar-light .navbar-nav .nav-link.active, .navigation-clean.navbar-light .navbar-nav .nav-link.active:focus, .navigation-clean.navbar-light .navbar-nav .nav-link.active:hover {
  box-shadow: none;
  background: none;
}

.navigation-clean.navbar .navbar-nav .nav-link {
  padding-left: 18px;
  padding-right: 18px;
}

.navigation-clean.navbar-light .navbar-nav .nav-link {
  position: relative;
  color: #0000008c;
}

.navigation-clean.navbar-light .navbar-nav .nav-link:hover, .navigation-clean.navbar-light .navbar-nav .nav-link.active {
  color: #000000b3 !important;
}

.nav-link.disable-link { pointer-events: none; }

.navigation-clean .navbar-nav > li .dropdown-menu {
  top: 140%;
  margin-left: 0px;
  box-shadow: none;
  background-color: transparent;
  border-radius: 2px;
}

.navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item {
  color: #0000008c;
}

.navigation-clean .dropdown-menu .dropdown-item:focus, .navigation-clean .dropdown-menu .dropdown-item:hover {
  background: #eee;
  color: inherit;
}

.form-label {
  font-size: large;
}



/* Responsive */
@media (min-width: 576px) and (max-width: 991.98px) {
  .container { max-width: 100%; }
}
@media (min-width: 992px) {
  .navbar .navbar-collapse {
    margin-top: 0rem;
    top: 23px;
    position: relative;
  }
  .navbar .navbar-collapse .nav-link.active:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -18px;
    left: 0;
    width: 100%;
    height: 9px;
    background: #FFF;
  }
  .navigation-clean .navbar-nav > li .dropdown-menu {
    margin-left: 0px;
    background-color: #FFF;
  }
  .form-label {
    font-size: larger;
  }
}

@media (max-width: 575.98px) {
  .nav-tabs a { margin-bottom: 2rem !important; }
  footer.footer {
    text-align: center;
    .justify-content-end { justify-content: center !important; }
  }
}

@media (max-width: 767.98px) {
  .navbar {
    .container { padding: 0rem 1.5rem; }
    .navbar-brand {
      max-width: 184px;
      img { max-height: 44px; }
    }
  }

  .nav-tabs a {
    top: 15px;
    font-size: 15px;
    padding: 0.2rem 0.5rem;
    img { height: 28px; }
    span.badge {
      padding: 0.3em 0.55em;
    }
  }

  .navigation-clean.navbar .navbar-nav .nav-link {
    font-size: 17px;
    padding: 0.25rem 18px;
  }

  .input-group .form-floating { width: 100%; margin: 0 0 1rem 0; }

  footer.actions {
    .container {
      padding: 2rem 1rem;
      .footer-btn-wrap {
        flex-wrap: wrap;
        > .btn { margin: 0 0 1.5rem auto !important; }
        .hstack {
          width: 100%;
          gap: 0rem !important;
          button { margin-left: 0.75rem; }
        }
      }
    }
  }

  .btn-lg { padding: 0.5rem; font-size: 1rem; }
  h5 { font-size: 1.1rem; }
  .btn-outline { font-size: 16px !important; }
  .h1, h1 { font-size: calc(1.375rem + 1vw); }
  .conditions { font-size: 0.9rem; padding: 1rem 0.5rem !important; }
}

@media (max-width: 991.98px) { }

@media (max-width: 1199.98px) {
  .form-floating > label { font-size: 16px; }
}

@media (max-width: 1399.98px) {}

.btn-edit {
   background: transparent url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNiIgaGVpZ2h0PSIxNiIgZmlsbD0iY3VycmVudENvbG9yIiBjbGFzcz0iYmkgYmktcGVuY2lsLXNxdWFyZSIgdmlld0JveD0iMCAwIDE2IDE2Ij4KICA8cGF0aCBkPSJNMTUuNTAyIDEuOTRhLjUuNSAwIDAgMSAwIC43MDZMMTQuNDU5IDMuNjlsLTItMkwxMy41MDIuNjQ2YS41LjUgMCAwIDEgLjcwNyAwbDEuMjkzIDEuMjkzem0tMS43NSAyLjQ1Ni0yLTJMNC45MzkgOS4yMWEuNS41IDAgMCAwLS4xMjEuMTk2bC0uODA1IDIuNDE0YS4yNS4yNSAwIDAgMCAuMzE2LjMxNmwyLjQxNC0uODA1YS41LjUgMCAwIDAgLjE5Ni0uMTJsNi44MTMtNi44MTR6Ii8+CiAgPHBhdGggZmlsbC1ydWxlPSJldmVub2RkIiBkPSJNMSAxMy41QTEuNSAxLjUgMCAwIDAgMi41IDE1aDExYTEuNSAxLjUgMCAwIDAgMS41LTEuNXYtNmEuNS41IDAgMCAwLTEgMHY2YS41LjUgMCAwIDEtLjUuNWgtMTFhLjUuNSAwIDAgMS0uNS0uNXYtMTFhLjUuNSAwIDAgMSAuNS0uNUg5YS41LjUgMCAwIDAgMC0xSDIuNUExLjUgMS41IDAgMCAwIDEgMi41djExeiIvPgo8L3N2Zz4=) center/1em auto no-repeat;
}

.countdown {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  font-size: 40px;
  padding: 0px;
}

.countdown > div {
  padding: 15px
}

.submissions-are-closed .edit-submission {
  display: none !important;
}