$label-colour: var(--bs-primary);
$toggle-colour: #c74b4b;
$white: #fff;
$focus-color: #ff0;

.dropdown-wrapper {
  position: relative;
  margin: 0 25px 0 10px;
  width: 95px;
  display: inline-block;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  text-align: left;
  margin-left: 0;
  & select {
    padding: 4px 2px;
    width: 150px;
  }
  @media screen and (max-width: 991px) {
    transform: scale(0.9);
  }
  @media screen and (max-width: 767px) {
    transform: scale(0.825);
  }
  @media screen and (max-width: 575px) {
    transform: scale(0.75);
  }
}
