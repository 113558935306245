.ReactModalPortal {
    z-index: 1025;
}

.ReactModal__Overlay.ReactModal__Overlay--after-open {
    z-index: 1030;
}

.btn-outline {
    border: solid 1px #c4c5c9;
    color: #4d4f54;
    text-decoration: none !important;
    text-transform: uppercase;
    font-size: 17px;
    padding: 6px 40px;
    white-space: normal;
    border-radius: 6px;
}

.btn-outline:hover {
    color: #7a8cca;
    background-color: transparent;
    border: solid 1px #c4c5c9;
}

.btn-outline:active {
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
    color: #7a8cca;
    background-color: transparent;
    border: solid 1px #c4c5c9;
}

.btn-outline:active:hover, .btn-outline:focus {
    border-color: #1b6d85;
}